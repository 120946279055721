<template>
<div id="audibleanalysis">
  <b-field grouped group-multiline class="box" position="is-centered">
    <b-select v-model.number="pfType" :disabled="isLoading" @input="updateRecords">
      <option v-for="v in pfTypes" :key="v.value" :value="v.value">{{v.text}}</option>
    </b-select>
    <b-select v-model.number="selectedAnaly" :disabled="isLoading" @input="updateRecords">
      <option v-for="(o, i) in analyUrls" :key="i" :value="i">{{o.name}}</option>
    </b-select>
    <b-datepicker
      placeholder="选择日期范围"
      v-model="rangeDates"
      :min-date="minDate"
      :max-date="maxDate"
      :loading="isLoading"
      icon="calendar-today"
      range
      @input="updateRecords">
    </b-datepicker>
    <button class="button is-warning" :disabled="isLoading" @click="updateRecords">刷新</button>
  </b-field>
  <b-field class="box">
    <audible-gid-selector ref="gidSelector" @change="onFilterChange" />
  </b-field>
  <b-field grouped class="box" position="is-centered">
    <div class="has-text-danger"><strong>{{totalName}}：</strong> {{(total)}}</div>
  </b-field>
  <b-tabs position="is-centered">
    <b-tab-item label="图表">
      <line-chart :chart-data="chartData" :height="200"></line-chart>
    </b-tab-item>
    <b-tab-item label="表格">
      <b-field class="box" position="is-centered" grouped group-multiline>
        <b-radio v-for="day in tableDays" :key="day" v-model="selectedDay"
          :name="day"
          :native-value="day">
          {{day}}
        </b-radio>
      </b-field>
      <b-field grouped class="box" position="is-centered">
        <div class="has-text-danger"><strong>{{selectedDay}}{{totalName}}：</strong> {{(selecedDayTotal)}}</div>
      </b-field>
      <b-table :data="tableData" paginated :per-page="perPage">
        <b-table-column #default="props" field="day" label="DAY" width="100" numeric>
            {{ props.row.day }}
        </b-table-column>
        <b-table-column #default="props" field="name" label="NAME" width="200" sortable>
            {{ props.row.name }}
        </b-table-column>
        <b-table-column #default="props" field="visit" label="VISIT" width="100" sortable numeric>
            {{ props.row.visit }}
        </b-table-column>
      </b-table>
    </b-tab-item>
  </b-tabs>
</div>
</template>

<script>
/**
 * 显示 Audible 的大盘数据
 */
import { nextChartColor, fromNow } from '@/core/util'
import LineChart from '@/components/charts/LineChart'
import AudibleGidSelector from '@/components/audible/AudibleGidSelector'
import type from '@/core/type'

export default {
  name: 'audibleanalysis',
  components: { LineChart, AudibleGidSelector },
  created () {
    this.updateRecords()
  },
  data () {
    return {
      perPage: 10,
      isLoading: false,
      pfTypes: type.pfTypes(),
      pfType: 0,
      total: 0,
      rdata: {},
      chartData: this.buildChartData(),
      tableDays: [],
      tableSets: null,
      selectedDay: null,
      minDate: new Date(2018, 4, 12),
      maxDate: new Date(),
      rangeDates: [fromNow({ days: -7 }), new Date()],
      selectedAnaly: 0,
      analyUrls: [
        { index: 0, url: '/audible/register/', name: '新增' },
        { index: 0, url: '/audible/active/', name: '活跃' }
      ]
    }
  },
  computed: {
    tableData () {
      if (this.selectedDay === null || this.tableSets === null) {
        return []
      }
      const datas = this.tableSets[this.selectedDay].data
      datas.sort((a, b) => b.visit - a.visit)
      return datas
    },
    selecedDayTotal () {
      if (this.selectedDay === null || this.tableSets === null) {
        return 0
      }
      return this.tableSets[this.selectedDay].total
    },
    totalName () {
      return `${this.analyUrls[this.selectedAnaly].name}总量`
    }
  },
  methods: {
    getUrlObj (gids) {
      const urlObj = this.analyUrls[this.selectedAnaly]
      if (urlObj === undefined) {
        return [null, null]
      }
      const filterObj = {
        from_date: this.formatDate(this.rangeDates[0]),
        to_date: this.formatDate(this.rangeDates[1]),
        pftype: this.pfType
      }
      if (Array.isArray(gids)) {
        filterObj.gids = JSON.stringify(gids.map(i => Number.parseInt(i)))
      }
      return [urlObj.url, filterObj]
    },
    async getRecords (gids) {
      if (this.isLoading) {
        return
      }
      const [url, filterObj] = this.getUrlObj(gids)
      if (url === null) {
        this.isLoading = false
        return
      }
      this.isLoading = true
      try {
        this.rdata = await this.mjp.get(url, filterObj)
        this.chartData = this.parseData(this.rdata.stat)
        this.$refs.gidSelector.updateGids(this.buildAllGids(this.rdata))
      } catch (e) {
        console.error(e)
      }
      this.isLoading = false
    },
    buildAllGids (data) {
      const gids = []
      for (const [gid, name] of Object.entries(data.gidict)) {
        gids.push({ gid, name })
      }
      return gids
    },
    parseData (stat, gids) {
      // 数据需要重新进行组合才能在图表中使用
      const dataSetsObj = {}
      let gnames = {}
      let total = 0
      stat.forEach(item => {
        if (gids && Array.isArray(gids) && !gids.includes(item.gid)) {
          // 如果提供了 gids ，仅处理 gids 包含的内容
          return
        }
        // 获得不重复的游戏名称
        gnames[item.name] = item.gid
        if (!dataSetsObj[item.date]) {
          dataSetsObj[item.date] = {}
        }
        dataSetsObj[item.date][item.name] = item.num
        // item.name = this.getChannelName(item.channel)
        total += item.num
      })
      this.total = total

      // 创建图表需要的数据
      const labels = Object.keys(dataSetsObj).sort()
      const datasets = []
      // 显示表格需要的数据，键名是 day，键值是一个 { total: 当天总量, data: [{name: 游戏名称, visit: 1111},.... ] }
      const tablesets = {}
      gnames = Object.keys(gnames)
      for (let i = 0; i < gnames.length; i++) {
        const name = gnames[i]
        const dataset = { label: name, data: [], borderColor: nextChartColor(i) }
        for (const label of labels) {
          const day = dataSetsObj[label]
          const visit = day[name] || 0
          dataset.data.push(visit)
          if (!tablesets[label]) {
            tablesets[label] = { total: 0, data: [] }
          }
          tablesets[label].data.push({ name, visit, day: label })
          tablesets[label].total += visit
        }
        datasets.push(dataset)
      }
      return { labels, datasets, tablesets }
    },
    clearRecords () {
      this.chartData = this.buildChartData()
      this.total = 0
      this.rdata = {}
    },
    buildChartData (labels, datasets) {
      labels = labels || []
      datasets = datasets || []
      return { labels, datasets }
    },
    updateRecords () {
      this.clearRecords()
      this.getRecords()
    },
    onFilterChange (selectedGids) {
      if (selectedGids.length > 0) {
        this.chartData = this.parseData(this.rdata.stat, selectedGids.map(i => Number.parseInt(i)))
        this.tableDays = this.chartData.labels
        this.tableSets = this.chartData.tablesets
        console.log('chartData %o', this.chartData)
        console.log('tableSets %o', this.tableSets)
      } else {
        this.total = 0
        this.chartData = this.buildChartData()
      }
    }
  }
}
</script>

<style>

</style>
