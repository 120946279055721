<template>
<div id="home">
  <h2 class="title is-2 has-text-centered" v-if="checkPermit(51, 53, 55)">大盘数据</h2>
  <h2 class="title is-4 has-text-centered" v-else>这里啥也没有</h2>
  <audible-analysis v-if="checkPermit(51, 53, 55)" />
</div>
</template>

<script>
/**
 * 主页显示
 */

import AudibleAnalysis from '@/components/audible/AudibleAnalysis'
import cache from '@/core/cache'

export default {
  name: 'home',
  components: { AudibleAnalysis },
  methods: {
    checkPermit (...permits) {
      const typeid = cache.getUserType()
      return typeid && permits.includes(typeid)
    }
  }
}
</script>

<style>
#home {
  padding: 12px;
}
</style>
