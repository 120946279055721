<template>
<div id="audiblegidselector">
  <b-switch v-model="selectAll" @input="onSelectAll">选择全部</b-switch>&nbsp;
  <b-checkbox v-model="selectedGids" v-for="o in gids"
    :native-value="o.gid" :key="o.gid">
    {{ o.name }}
  </b-checkbox>
</div>
</template>

<script>
/**
 渠道选择器，以 CheckBox 的方式展现
 */

export default {
  data () {
    return {
      selectAll: true,
      gids: [],
      selectedGids: []
    }
  },
  methods: {
    updateGids (gids) {
      this.selectedGids = gids.map(item => Number.parseInt(item.gid))
      this.gids = gids
      this.selectAll = true
    },
    onSelectAll (select) {
      if (select) {
        this.gids.forEach(item => {
          if (!this.selectedGids.includes(item.gid)) {
            this.selectedGids.push(item.gid)
          }
        })
      } else {
        this.selectedGids.splice(0, this.selectedGids.length)
      }
      this.$emit('change', this.selectedGids)
    }
  },
  watch: {
    selectedGids (to, from) {
      this.$emit('change', this.selectedGids)
    }
  }
}
</script>
